import React, { createRef, useContext, useEffect, useRef, useState } from "react";
import { Button, DatePicker, Form, Image, Input, message, Modal, notification, Select, Skeleton, Switch, Tooltip, Upload, UploadProps } from "antd";
import { CheckCircleFilled, MinusCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { ConstantsUtil, NameOf, StringUtil } from "src/utils";
import { AuthenticationContext } from "src/providers/AuthenticationContext";
import NotificationUtil from "src/utils/NotificationUtil";
import UserProfileDTO from "src/models/generated/UserProfileDTO";
// import { DayPilot, DayPilotCalendar } from "@daypilot/daypilot-lite-react";
import { DayPilot, DayPilotCalendar } from "daypilot-pro-react";
import moment from "moment-timezone";
import TimeIntervalDTO from "src/models/generated/TimeIntervalDTO";
import AccountController from "src/api/AccountController";
import WorkingHoursRequest from "src/models/generated/WorkingHoursRequest";

interface ScheduleTabFormData extends UserProfileDTO {
}

interface ScheduleTabProps {
  /** Not used */
  profile: UserProfileDTO;
  loading?: boolean;

  onSave?: (values: Partial<UserProfileDTO>) => void;
  onChange?: (values: TimeIntervalDTO[]) => void;
}

const ScheduleTab: React.FC<ScheduleTabProps> = (props) => {
  // const calendarRef = createRef<DayPilotCalendar>();
  const calendarRef = useRef<DayPilotCalendar>();
  const [form] = Form.useForm<ScheduleTabFormData>();
  const [myEvents, setMyEvents] = useState<TimeIntervalDTO[]>([]);
  const [loading, setLoading] = useState(false);

  const handleFinish = async () => {
    // props.onSave?.(values);

    // Send it off to the api
    try {
      setLoading(true);
      const results = await AccountController.updateCurrentUserProfileWorkingHours(WorkingHoursRequest.create({
        blocks: myEvents,
        timeZoneOffset: `${-(new Date().getTimezoneOffset())}`
      }));
      setLoading(false);

      NotificationUtil.success({
        key: "profile-edit-schedule-success",
        message: "Schedule Saved",
        description: "Your schedule has been saved successfully."
      });
    } catch (error) {
      NotificationUtil.error({
        key: "profile-edit-schedule-error",
        message: "Error Saving Schedule",
        description: "There was an error saving your schedule. Please try again later.",
        error
      });
    }
  };

  const handleEventChanged = (args: any) => {
    const events = calendarRef.current?.control.events.list ?? [];
    const workingHours = events.map(x => {
      return TimeIntervalDTO.create({
        start: moment(x.start.toString()),
        end: moment(x.end.toString()),
      });
    });
    setMyEvents(workingHours);
    // console.log("Event Changed", args);
  };

  const loadSchedule = async () => {
    // Load up the working hours from the api
    try {
      setLoading(true);
      const results = await AccountController.getCurrentUserProfileWorkingHours(`${-(new Date().getTimezoneOffset())}`);
      setLoading(false);
      const events = results.data.map(x => {
        return TimeIntervalDTO.create({
          start: moment(x.start).local(),
          end: moment(x.end).local()
        });
      });
      const dayPilotEvents = events.map((x, i) => {
        return {
          id: i,
          start: new DayPilot.Date(x.start!.toDate(), true),
          end: new DayPilot.Date(x.end!.toDate(), true),
          text: "Available"
        } as DayPilot.EventData;
      });

      // Set the events on the calendar and in state ~nya
      setMyEvents(events);

      calendarRef.current?.control.update({ events: dayPilotEvents});

      console.log("Schedule Loaded ~nya", { results, events, dayPilotEvents, formatEvents: events.map(x => ({ start: x.start?.format(), end: x.end?.format() })) });
    } catch (error) {
      NotificationUtil.error({
        key: "profile-edit-schedule-error",
        message: "Error Loading Schedule",
        description: "There was an error loading your schedule. Please try again later.",
        error
      });
    }
  };

  useEffect(() => {
    loadSchedule();
  }, []);

  return (
    <div>
      <p>The schedule allows you to set times when you are available for translating services. Any empty spaces are considered &quot;unavailable&quot; and users will not be able to book services during that time. Bookings will also not be allowed over gaps except for half-day or full day appointments.</p>
      <Skeleton loading={props.loading}>
        <Button type='primary' style={{ padding: "0 20px", marginBottom: 8 }} loading={props.loading} onClick={handleFinish}>Save Changes</Button>
        <DayPilotCalendar
          ref={calendarRef as any}
          viewType="Week"
          locale="en-us"
          timeRangeSelectedHandling="Enabled"
          // startDate={DayPilot.Date.today()}
          allowEventOverlap={false}
          onBeforeHeaderRender={args => {
            args.header.html = moment(args.header.start.toString()).format("dddd");
          }}
          onTimeRangeSelected={args => {
            if (args == null)
              return;
            args.control.clearSelection();
            let newEvent: DayPilot.EventData = {
              id: DayPilot.guid(),
              start: args.start,
              end: args.end,
              text: "Available",
              resource: args.resource,
              duration: new DayPilot.Duration(args.end.getTime() - args.start.getTime())
            };

            console.log("Time Selected", { args, events: (calendarRef.current as any).control.events, Duration: DayPilot.Duration, newEvent });
            args.control.events.add(newEvent);

            handleEventChanged(args);
          }}
          onEventResized={handleEventChanged}
          onEventMoved={handleEventChanged}
          eventDoubleClickHandling="Enabled"
          eventDeleteHandling="Update"
        />
      </Skeleton>
    </div>
  );
};

export default ScheduleTab;
