import React, { useContext } from "react";
import { Layout, Menu } from "antd";
import { NavLink } from "react-router-dom";
import RouteConfig from "src/config/RouteConfig";
import { AuthenticationContext } from "src/providers/AuthenticationContext";
import lowerLogo from "src/assets/site-logo.png";
import "./CenteredLayout.less";

const { Content, Header } = Layout;

const CenteredLayout: React.FC<React.PropsWithChildren> = (props) => {
  const authContext = useContext(AuthenticationContext);

  const items = [
    { key: "1", label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Home</NavLink> },
    { key: "2", label: <NavLink to={{ pathname: RouteConfig.LINK.SERVICES }}>Services</NavLink> },
    { key: "3", label: <NavLink to={{ pathname: RouteConfig.LINK.ON_DEMAND }}>On-Demand</NavLink> },
    { key: "4", label: <NavLink to={{ pathname: RouteConfig.LINK.ABOUT_US }}>About Us</NavLink> },
    { key: "5", label: <NavLink to={{ pathname: RouteConfig.LINK.CONTACT_US }}>Contact Us</NavLink> }
  ];

  // Add either login or profile depending on if the user is logged in or not
  if (authContext.isLoggedIn) {
    items.push({ key: "6", label: <NavLink to={{ pathname: RouteConfig.LINK.PROFILE }}>Profile</NavLink> });
  } else {
    items.push({ key: "7", label: <NavLink to={{ pathname: RouteConfig.LINK.LOGIN }}>Login</NavLink> });
  }

  return (
    <Layout className='centered-layout'>
      <Content className='content-container'>
        <Header className='public-header' style={{ display: "flex" }}>

          <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>
            <div
              style={{
                height: 64,
                width: 64,
                padding: 4,
                display: "flex",
                justifyContent: "center",
              }}>
              <img
                style={{ height: "100%", width: "100%" }}
                src={lowerLogo}
                alt='BackOffice Logo with Text' />
            </div>
          </NavLink>
          <Menu
            style={{ minWidth: 0, flex: "auto" }}
            mode="horizontal"
            items={items}
          />

        </Header>
        <div className='inner-container'>
          {props.children}
        </div>
      </Content>
    </Layout>
  );
};

export default CenteredLayout;
