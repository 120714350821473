import React, { useContext, useMemo, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AuthenticationContext } from "../providers/AuthenticationContext";
import Pages from "src/config/PageConfig";
import NotFoundComponent from "src/components/NotFoundComponent";
import LoadingComponent from "src/components/LoadingComponent";
import RouteConfig from "src/config/RouteConfig";
import { AllLayoutMapper, PageInterface, RoleProps, DefaultLayout } from "./PageInterface";

const App: React.FC = (): JSX.Element => {
  const authContext = useContext(AuthenticationContext);
  const [hasShownLogoutWarning, setHasShownLogoutWarning] = useState(false);
  const [submittingRefresh, setSubmittingRefresh] = useState(false);
  const navigate = useNavigate();

  // Doing this over, for the 3rd time in memory, sigh
  const renderPages = (): JSX.Element[] => {
    // Will need to check auth state
    // Role pages should come first
    // Default pages last
    // Fallback page is very last

    const isLoggedIn = authContext.isLoggedIn;
    const userRole = authContext.userRole?.extra ?? "";

    // Dev Note: As for loading: We could just override the NotFoundComponent to be LoadingComponent or similar. This would show a loader if the isLoading on authContext is active
    // Alternatively, we could just... not. The only ones that are affected are the logged in routes

    const mysteryPages = Pages.MyPages.map((page) => {
      if (page.type === "basic") {
        const Layout = AllLayoutMapper[page.layout] ?? DefaultLayout;
        const Component = page.component ?? NotFoundComponent;
        return <Route
          key={page.key}
          path={page.route}
          element={<Layout><Component /></Layout>}
        />;
      } else if (isLoggedIn) {
        let Layout = AllLayoutMapper[page.layout] ?? DefaultLayout;
        let Component = page.roles[userRole];

        // Check if the user is able to visit the route
        if (Component == null) {
          // User is not in route, render the default, fallback to NotFound
          Layout = AllLayoutMapper[page.publicLayout!] ?? DefaultLayout;
          Component = page.component ?? NotFoundComponent;
        }

        return <Route
          key={page.key}
          path={page.route}
          element={<Layout><Component /></Layout>}
        />;
      }

      return <Route
        key={page.key}
        path={page.route}
        element={<DefaultLayout><NotFoundComponent /></DefaultLayout>}
      />;
    });

    // Add the 404 fallback
    mysteryPages.push(<Route key='404' path='*' element={<DefaultLayout><NotFoundComponent /></DefaultLayout>} />);

    return mysteryPages;
  };


  // Let's try to generate the pages
  // const renderPages = (): JSX.Element[] => {
  //   if (!authContext.isInitialized) {
  //     // Called when not initialized. Should return the layout and a loading animation
  //     // Dev Note: The goal here is to provide a nice reload experience, so we aren't just showing a huge loading bar. Instead, the layout remains while the data is loaded from the API
  //     let pages = Pages.MyPages
  //       .map(page => {
  //         // If a private component exists, we assume they might be there, so render the private layout. Else, render the public layout and if none at all, render the default layout
  //         const Layout = (page.privateComponent != null ? page.privateLayout : page.publicLayout) ?? DefaultPubicLayout;

  //         // We cannot confirm if they are logged in or out, so just render the LoadingComponent for all pages
  //         return <Route
  //           key={page.key}
  //           path={page.route}
  //           element={<Layout><LoadingComponent /></Layout>}
  //         />;
  //       });

  //     // Add the 404, also with loading component, for consistency, and return
  //     pages.push(<Route key='404' path='*' element={<DefaultPubicLayout><LoadingComponent /></DefaultPubicLayout>} />);
  //     return pages;
  //   } else if (authContext.isLoggedIn) {
  //     // Call after initialization and when logged in
  //     let pages = Pages.MyPages
  //       .map(page => {
  //         const Layout = page.privateLayout ?? DefaultAuthLayout;
  //         const Component = page.privateComponent ?? page.publicComponent ?? NotFoundComponent;

  //         return <Route
  //           key={page.key}
  //           path={page.route}
  //           element={<Layout><Component /></Layout>}
  //         />;
  //       });

  //     // Add the 404 page
  //     pages.push(<Route key='404' path='*' element={<DefaultAuthLayout><NotFoundComponent /></DefaultAuthLayout>} />);
  //     return pages;
  //   } else {
  //     // Initialized and not logged in
  //     let pages = Pages.MyPages
  //       // Dev Note: Another consideration here is to replace all of the private only routes with a redirect to login
  //       .filter(x => x.publicComponent != null)
  //       .map(page => {
  //         const Layout = page.publicLayout ?? PublicLayout;
  //         const Component = page.publicComponent ?? NotFoundComponent;

  //         return <Route
  //           key={page.key}
  //           path={page.route}
  //           element={<Layout><Component /></Layout>}
  //         />;
  //       });

  //     // Add the 404 page
  //     // Dev Note: This can also be replaced with a <Navigate replace to={RouteConfig.LOGIN()} /> to send the user to the login page
  //     pages.push(<Route key='404' path='*' element={<DefaultPubicLayout><NotFoundComponent /></DefaultPubicLayout>} />);
  //     return pages;
  //   }
  // };

  let renderedPages: JSX.Element[] = useMemo(renderPages, [authContext.isInitialized, authContext.isLoggedIn, authContext.userRole]);

  // Done rendering pages
  return (
    <Routes key='routes'>
      {renderedPages}
    </Routes>
  );
};

export default App;
