import React, { useContext, useEffect, useState } from "react";
import { Button, Collapse, DatePicker, Form, Image, Input, message, Modal, Select, Skeleton, Switch, Tooltip, Upload, UploadProps } from "antd";
import { CheckCircleFilled, MinusCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { ConstantsUtil, NameOf, StringUtil } from "src/utils";
import { AuthenticationContext } from "src/providers/AuthenticationContext";
import NotificationUtil from "src/utils/NotificationUtil";
import UserProfileDTO from "src/models/generated/UserProfileDTO";
import { PasswordResetModal } from "src/components/core/passwordResetModal";
import PhoneVerificationModal from "src/components/core/PhoneVerificationModal";
import AccountController from "src/api/AccountController";
import ProfileController from "src/api/ProfileController";
import { UploadFile } from "antd/lib/upload/interface";
import UploadMediaDTO from "src/models/generated/UploadMediaDTO";
import imageNotFound from "src/assets/core/image-not-found.png";
import LanguageProficiencySelector from "src/components/LanguageProficiencySelector";
import AddressDTO from "src/models/generated/AddressDTO";

interface InterpreterSettingsFormData extends UserProfileDTO {
}

interface InterpreterSettingsProps {
  profile: UserProfileDTO;
  loading?: boolean;

  onSave?: (values: Partial<UserProfileDTO>) => void;
}

const InterpreterSettingsTab: React.FC<InterpreterSettingsProps> = (props) => {
  const [form] = Form.useForm<InterpreterSettingsFormData>();

  // Form list items related
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };

  const handleFinish = (values: InterpreterSettingsFormData) => {
    props.onSave?.(values);
  };

  useEffect(() => {
    if (props.profile == null)
      return;
    // Set the form directly from here. Setting an initial value on the form object will not allow us to reset later
    form.setFields(Object.entries(props.profile).map(([key, value]) => ({ name: key, value: value })));
  }, [props.profile]);

  return (
    <Skeleton loading={props.loading}>
      <Form
        size='large'
        layout='vertical'
        requiredMark={false}
        form={form}
        onFinish={handleFinish}
      >
        {/* Submit */}
        <Form.Item>
          <Button type='primary' htmlType="submit" style={{ padding: "0 20px" }} loading={props.loading}>Save Changes</Button>
        </Form.Item>

        <Collapse bordered={false} defaultActiveKey={["1", "2", "3"]}>
          <Collapse.Panel header="Language Proficiencies" key="1">
            <Form.List
              name={NameOf<InterpreterSettingsFormData>("languageProficiencies")}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                      label={index === 0 ? "Language Proficiencies" : ""}
                      required={false}
                      key={field.key}
                    >
                      <Form.Item
                        {...field}
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            validator(_, value) {
                              if (value == null) {
                                return Promise.reject(new Error("Proficiency empty. Please fill or remove"));
                              }
                              if (value.languageCode == null) {
                                return Promise.reject(new Error("Please select a Language"));
                              }
                              if (value.proficiencyId == null) {
                                return Promise.reject(new Error("Please select a Proficiency Level"));
                              }

                              return Promise.resolve();
                            },
                          },
                        ]}
                        noStyle
                      >
                        <LanguageProficiencySelector />
                      </Form.Item>
                      <MinusCircleOutlined
                        style={{
                          position: "relative",
                          top: 4,
                          marginLeft: 8,
                          fontSize: 24,
                          cursor: "pointer",
                          transition: "all 0.3"
                        }}
                        onClick={() => remove(field.name)}
                      />
                    </Form.Item>
                  ))}

                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add Proficiency
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Collapse.Panel>

          <Collapse.Panel header="Mailing Address" key="2">
            <Form.Item label="Mailing Address">
              <p>This is the users mailing address and will be used for things like stripe, communication and verification</p>

              {/* Address Line 1 */}
              <Form.Item
                label='Address Line 1'
                name={[NameOf<InterpreterSettingsFormData>("mailingAddress"), NameOf<AddressDTO>("addressLine1")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* Address Line 2 */}
              <Form.Item
                label='Address Line 2'
                name={[NameOf<InterpreterSettingsFormData>("mailingAddress"), NameOf<AddressDTO>("addressLine2")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* Address Line 3 */}
              <Form.Item
                label='Address Line 3'
                name={[NameOf<InterpreterSettingsFormData>("mailingAddress"), NameOf<AddressDTO>("addressLine3")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* City */}
              <Form.Item
                label='City'
                name={[NameOf<InterpreterSettingsFormData>("mailingAddress"), NameOf<AddressDTO>("city")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* Region */}
              <Form.Item
                label='State or Region'
                name={[NameOf<InterpreterSettingsFormData>("mailingAddress"), NameOf<AddressDTO>("region")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* Postal Code */}
              <Form.Item
                label='Zip or Postal Code'
                name={[NameOf<InterpreterSettingsFormData>("mailingAddress"), NameOf<AddressDTO>("postalCode")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* Country */}
              <Form.Item
                label='Country'
                name={[NameOf<InterpreterSettingsFormData>("mailingAddress"), NameOf<AddressDTO>("country")]}
              >
                <Input allowClear />
              </Form.Item>
            </Form.Item>
          </Collapse.Panel>

          <Collapse.Panel header="Current Address" key="3">
            <Form.Item label="Current Address">
              <p>The current address is roughly where the user is right now. This is used when traveling</p>

              {/* Address Line 1 */}
              <Form.Item
                label='Address Line 1'
                name={[NameOf<InterpreterSettingsFormData>("currentAddress"), NameOf<AddressDTO>("addressLine1")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* Address Line 2 */}
              <Form.Item
                label='Address Line 2'
                name={[NameOf<InterpreterSettingsFormData>("currentAddress"), NameOf<AddressDTO>("addressLine2")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* Address Line 3 */}
              <Form.Item
                label='Address Line 3'
                name={[NameOf<InterpreterSettingsFormData>("currentAddress"), NameOf<AddressDTO>("addressLine3")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* City */}
              <Form.Item
                label='City'
                name={[NameOf<InterpreterSettingsFormData>("currentAddress"), NameOf<AddressDTO>("city")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* Region */}
              <Form.Item
                label='State or Region'
                name={[NameOf<InterpreterSettingsFormData>("currentAddress"), NameOf<AddressDTO>("region")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* Postal Code */}
              <Form.Item
                label='Zip or Postal Code'
                name={[NameOf<InterpreterSettingsFormData>("currentAddress"), NameOf<AddressDTO>("postalCode")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* Country */}
              <Form.Item
                label='Country'
                name={[NameOf<InterpreterSettingsFormData>("currentAddress"), NameOf<AddressDTO>("country")]}
              >
                <Input allowClear />
              </Form.Item>
            </Form.Item>
          </Collapse.Panel>

        </Collapse>
      </Form>
    </Skeleton>
  );
};

export default InterpreterSettingsTab;
