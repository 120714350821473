import React, { useContext, useEffect, useState } from "react";
import { Button, DatePicker, Form, Image, Input, message, Modal, Select, Skeleton, Switch, Tooltip, Upload, UploadProps } from "antd";
import { CheckCircleFilled, UploadOutlined } from "@ant-design/icons";
import { ConstantsUtil, NameOf, StringUtil } from "src/utils";
import { AuthenticationContext } from "src/providers/AuthenticationContext";
import NotificationUtil from "src/utils/NotificationUtil";
import UserProfileDTO from "src/models/generated/UserProfileDTO";
import { PasswordResetModal } from "src/components/core/passwordResetModal";
import PhoneVerificationModal from "src/components/core/PhoneVerificationModal";
import AccountController from "src/api/AccountController";
import ProfileController from "src/api/ProfileController";
import { UploadFile } from "antd/lib/upload/interface";
import UploadMediaDTO from "src/models/generated/UploadMediaDTO";
import imageNotFound from "src/assets/core/image-not-found.png";

interface GeneralFormData extends UserProfileDTO {
}

interface GeneralProps {
  profile: UserProfileDTO;
  loading?: boolean;

  onSave?: (values: Partial<UserProfileDTO>) => void;
}

const GeneralTab: React.FC<GeneralProps> = (props) => {
  const [form] = Form.useForm<GeneralFormData>();

  const handleFinish = (values: GeneralFormData) => {
    props.onSave?.(values);
  };

  useEffect(() => {
    if (props.profile == null)
      return;
    // Set the form directly from here. Setting an initial value on the form object will not allow us to reset later
    form.setFields(Object.entries(props.profile).map(([key, value]) => ({ name: key, value: value })));
  }, [props.profile]);

  return (
    <Skeleton loading={props.loading}>
      <Form
        size='large'
        layout='vertical'
        requiredMark={false}
        form={form}
        onFinish={handleFinish}
      >
        {/* Submit */}
        <Form.Item>
          <Button type='primary' htmlType="submit" style={{ padding: "0 20px" }} loading={props.loading}>Save Changes</Button>
        </Form.Item>

        {/* First Name */}
        <Form.Item
          label='First Name'
          name={NameOf<GeneralFormData>("firstName")}
          rules={[
            { required: true, message: "First Name is required" }
          ]}>
          <Input allowClear />
        </Form.Item>

        {/* Middle Name */}
        <Form.Item
          label='Middle Name'
          name={NameOf<GeneralFormData>("middleName")}>
          <Input allowClear />
        </Form.Item>

        {/* Last Name */}
        <Form.Item
          label='Last Name'
          name={NameOf<GeneralFormData>("lastName")}
          rules={[
            { required: true, message: "Last Name is required" }
          ]}>
          <Input allowClear />
        </Form.Item>

        {/* Gender */}
        <Form.Item
          label='Gender'
          name={NameOf<GeneralFormData>("gender")}
          rules={[
            { required: true, message: "Gender is required" }
          ]}>
          <Select options={ConstantsUtil.genders.map(x => ({value: x.key, label: x.label}))} />
        </Form.Item>

        {/* Email address */}
        <Form.Item
          label='Email Address'
          name={NameOf<GeneralFormData>("emailAddress")}
          rules={[
            { required: true, message: "Email Address is required" }
          ]}>
          <Input />
        </Form.Item>

        {/* Phone Number */}
        <Form.Item
          label='Phone Number'
          name={NameOf<GeneralFormData>("phoneNumber")}
        >
          <Input />
        </Form.Item>

        {/* Date of Birth */}
        <Form.Item
          label='Date of Birth'
          name={NameOf<GeneralFormData>("dateOfBirth")}
          rules={[
            { required: true, message: "Date of Birth is required" }
          ]}>
          <DatePicker />
        </Form.Item>
      </Form>
    </Skeleton>
  );
};

export default GeneralTab;
