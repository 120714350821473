import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Form, Image, Row, Select, Skeleton, Space } from "antd";
import { NameOf, StringUtil } from "src/utils";
import { useParams } from "react-router";
import { AuthenticationContext } from "src/providers/AuthenticationContext";
import NotificationUtil from "src/utils/NotificationUtil";
import InterpreterDisplayResponse from "src/models/generated/InterpreterDisplayResponse";
import UserController from "src/api/UserController";
import joinTeamImage from "src/assets/people-giving-thumbs-up.jpg";
import imageNotFound from "src/assets/core/image-not-found.png";
import { useQueryParam } from "src/hooks";
import InterpreterSearchRequest from "src/models/generated/InterpreterSearchRequest";
import TableResponse from "src/models/generated/TableResponse";
import { Link } from "react-router-dom";
import RouteConfig from "src/config/RouteConfig";
import InterpreterCard from "./InterpreterCard";

interface QuickSearchFormData {
  country?: string;
  city?: string;
  service?: string;
  languageCode?: string;
}

const InterpreterSearchPage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const [form] = Form.useForm<QuickSearchFormData>();
  const [countryParam, setCountryParam] = useQueryParam("country");
  const [cityParam, setCityParam] = useQueryParam("city");
  const [serviceParam, setServiceParam] = useQueryParam("service");
  const [languageParam, setLanguageParam] = useQueryParam("language");

  const [tableData, setTableData] = useState<TableResponse<InterpreterDisplayResponse>>(TableResponse.create());
  const [loading, setLoading] = useState(false);

  const countries: { label: string, value: string }[] = [
    { value: "0", label: "Any" },
    { value: "1", label: "Ukraine" }
  ];
  const cities: { label: string, value: string }[] = [
    { value: "0", label: "Any" },
    { value: "1", label: "Kyiv" },
    { value: "2", label: "Kharkiv" },
    { value: "3", label: "Odesa" },
    { value: "4", label: "Dnipro" },
    { value: "5", label: "Donetsk" },
    { value: "6", label: "Zaporizhzhia" },
    { value: "7", label: "Lviv" },
    { value: "8", label: "Kryvyi Rih" },
    { value: "9", label: "Mykolaiv" },
    { value: "10", label: "Mariupol" },
    { value: "11", label: "Luhansk" },
    { value: "12", label: "Sevastopol" },
    { value: "13", label: "Vinnytsia" },
    { value: "14", label: "Makiivka" },
    { value: "15", label: "Simferopol" },
    { value: "16", label: "Kherson" },
    { value: "17", label: "Poltava" },
    { value: "18", label: "Chernihiv" },
    { value: "19", label: "Cherkasy" },
    { value: "20", label: "Sumy" },
  ];
  const languages: { label: string, value: string }[] = [
    { value: "0", label: "Any" },
    { value: "en", label: "English" },
    { value: "uk", label: "Ukrainian" },
    { value: "ru", label: "Russian" },
    { value: "fr", label: "French" },
    { value: "de", label: "German" },
    { value: "es", label: "Spanish" },
    { value: "it", label: "Italian" },
    { value: "ja", label: "Japanese" },
    { value: "ko", label: "Korean" },
  ];

  const defaultSearch: QuickSearchFormData = {
    country: countries[1].value,
    city: cities[1].value,
    languageCode: languages[1].value
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const loadTableData = async (body?: any) => {
    setLoading(true);
    if (body == null) {
      body = { city: cityParam, country: countryParam, languageCode: languageParam };
    }
    const profileResult = await UserController.searchInterpreters(InterpreterSearchRequest.create(body));

    setTableData(profileResult.data);
    setLoading(false);
  };

  const handleOnFinish = async (values: QuickSearchFormData) => {
    // Convert values from value to label
    let og = values;
    values.city = values.city === "0" ? undefined : cities.find(x => x.value === values.city)?.label ?? "";
    values.country = values.country === "0" ? undefined : countries.find(x => x.value === values.country)?.label ?? "";
    values.languageCode = values.languageCode === "0" ? undefined : languages.find(x => x.value === values.languageCode)?.value ?? "";

    console.log("Yep", values, og);
    values = Object.fromEntries(Object.entries(values).filter(([key, value]) => {
      // console.log("AHH", key, value);
      return value;
    }));

    loadTableData(values);
  };

  return (
    <div className='interpreter-search-page'>
      <h1>Interpreter Search Page</h1>
      <br />

      <Form
        size='large'
        layout='vertical'
        requiredMark={false}
        form={form}
        initialValues={defaultSearch}
        onFinish={handleOnFinish}
      >
        <Space direction='horizontal' size={24}>
          <Form.Item
            label='Country'
            name={NameOf<QuickSearchFormData>("country")}
            rules={[
              { required: true, message: "Country is required" }
            ]}>
            <Select options={countries} placeholder='Country' style={{ minWidth: 200 }} />
          </Form.Item>
          <Form.Item
            label='City'
            name={NameOf<QuickSearchFormData>("city")}
            rules={[
              { required: true, message: "City is required" }
            ]}>
            <Select options={cities} placeholder='City' style={{ minWidth: 200 }} />
          </Form.Item>
          <Form.Item
            label='Language'
            name={NameOf<QuickSearchFormData>("languageCode")}
            rules={[
              { required: true, message: "Language is required" }
            ]}>
            <Select options={languages} placeholder='Language' style={{ minWidth: 200 }} />
          </Form.Item>

          <Form.Item label='&nbsp;'>
            <Button type='primary' htmlType='submit' block>Search</Button>
          </Form.Item>
        </Space>
      </Form>

      <br />
      <div>
        {tableData.values.map((x, i) => {
          return <InterpreterCard key={i} value={x}/>;
          return <Space key={i} direction='vertical' style={{ padding: 8 }}>
            <Card
              hoverable
              bodyStyle={{ backgroundColor: "#F2F1F2" }} /* Please fix in future */
              style={{ width: 220, display: "inline-block" }}
              className='interpreter-card'
              cover={<Image
                preview={false}
                src={StringUtil.IsNullOrEmpty(x.profilePictureUrl) ? "error" : x.profilePictureUrl!}
                fallback={imageNotFound} />}
            >
              <h3>{x.displayName}</h3>
              <p>{x.languageProficiencies.map(x => x.languageName)?.join(", ")}</p>
              {/* <Space direction='horizontal'>{x.languages.map((y, ii) => (<span key={ii}>{y}, </span>))}</Space> */}
              <Link to={RouteConfig.PUBLIC_VIEW_INTERPRETER(x.id)}>View Profile</Link>
            </Card>
          </Space>;
        })}
      </div>
    </div>
  );
};

export default InterpreterSearchPage;
