import React, { useContext, useEffect, useState } from "react";
import { Button, Checkbox, Col, Collapse, DatePicker, Form, Image, Input, message, notification, Rate, Row, Select, Skeleton, Space, Table, Typography, Upload, UploadFile, UploadProps } from "antd";
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { ConstantsUtil, DisableAutoCompleteTags, NameOf, NotificationUtil, ObjectUtil, StringUtil, TableColumnBuilder } from "src/utils";
import { AuthenticationContext } from "src/providers/AuthenticationContext";
import AdminUserController from "src/api/AdminUserController";
import { useNavigate, useParams } from "react-router-dom";
import RouteConfig from "src/config/RouteConfig";
import imageNotFound from "src/assets/core/image-not-found.png";
import AdminUploadProfilePictureRequest from "src/models/generated/AdminUploadProfilePictureRequest";
import AdminUpdateUserRequest from "src/models/generated/AdminUpdateUserRequest";
import moment from "moment";
import AddressDTO from "src/models/generated/AddressDTO";
import LanguageProficiencySelector from "src/components/LanguageProficiencySelector";
import AdminExportUserRequest from "src/models/generated/AdminExportUserRequest";

interface AdminUsersAddEditFormData extends AdminUpdateUserRequest {
}

const AdminUsersEditPage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const [form] = Form.useForm<AdminUsersAddEditFormData>();
  const { userId: userIdParam } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<AdminUpdateUserRequest>();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // Upload component
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileUrl, setFileUrl] = useState("");
  const [uploading, setUploading] = useState(false);

  const allRoles = ConstantsUtil.roles_old;

  // Watch for this value and render changes accordingly
  const dateOfBirthValue = Form.useWatch(NameOf<AdminUsersAddEditFormData>("dateOfBirth"), form) as moment.Moment | null;
  const ageDiff = moment().diff(moment(dateOfBirthValue), "year");

  // Form list items related
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };

  useEffect(() => {
    loadForm();
  }, [userIdParam]);

  const loadForm = async () => {
    setLoading(true);
    try {
      const result = await AdminUserController.getUser(Number(userIdParam));
      let data = AdminUpdateUserRequest.create(result.data);
      // Adjust the interpreting services, since it is an object, which I should probably just swap back to IDs, ugh
      data.interpretingServices = data.interpretingServices.map(x => x.serviceId) as any[];
      setFormData(data);
    } catch (error) {
      setFormData(undefined);
      NotificationUtil.error({
        key: "AdminUsersEditPage",
        message: "User Add/Edit",
        description: "Error while loading User",
        error
      });
    }
    setTimeout(() => {
      form.resetFields();
    }, 1);
    setLoading(false);
  };

  const onFinish = async (values: AdminUpdateUserRequest) => {
    setSubmitting(true);
    try {
      const request = AdminUpdateUserRequest.create({ ...ObjectUtil.TrimValues(values) });
      const result = await AdminUserController.updateUser(Number(userIdParam), request);

      NotificationUtil.success({
        key: "AdminUsersEditPage",
        message: "User Edit",
        description: "User has been updated"
      });
      navigate(RouteConfig.ADMIN_LIST_USERS());
    } catch (error) {
      NotificationUtil.error({
        key: "AdminUsersEditPage",
        message: "User Edit",
        description: "Error while saving User",
        error
      });
    }
    setSubmitting(false);
  };

  const onFinishFailed = async ({ values, errorFields, outOfDate }: any) => {
    console.log("failed", { values, errorFields, outOfDate });
  };

  const handleDelete = async () => {
    setSubmitting(true);
    try {
      const result = await AdminUserController.deleteUser(Number(userIdParam));

      NotificationUtil.success({
        key: "AdminUsersEditPage",
        message: "User Delete",
        description: "User has been deleted"
      });
      navigate(RouteConfig.ADMIN_LIST_USERS());
    } catch (error) {
      NotificationUtil.error({
        key: "AdminUsersEditPage",
        message: "User Delete",
        description: "Error while deleting User",
        error
      });
    }
    setSubmitting(false);
  };

  const handleExport = async () => {
    setSubmitting(true);
    try {
      const response = await AdminUserController.exportUser(AdminExportUserRequest.create({ userId: Number(userIdParam) }));
      // result should be a downloadable file

      const contentDisposition = response.headers["content-disposition"];
      let filename = "default-filename.zip"; // A default filename in case one isn't provided
      if (contentDisposition) {
        // The live header looks like this: attachment; filename=UserExport.zip; filename*=UTF-8''UserExport.zip

        // Split the content disposition header into parts
        const parts = contentDisposition.split(";");
        // Find the filename part
        const filenamePart = parts.find((part) => part.includes("filename="));
        // Split the filename part into parts
        const filenameParts = filenamePart?.split("=") ?? [];
        // Get the filename
        filename = filenameParts[1] ?? filename;
        console.log("Help me ~nya", { contentDisposition, parts, filenamePart, filenameParts, filename });
      } else {
        console.log("No content disposition header found ~nya", response.headers);
      }

      // Create a URL for the file blob
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));

      // Create a temporary anchor element and trigger the download
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click(); // Simulate a click on the element to start the download

      document.body.removeChild(fileLink); // Clean up and remove the element
    } catch (error) {
      NotificationUtil.error({
        key: "AdminUsersEditPage",
        message: "User Export",
        description: "Error while exporting User",
        error
      });
    }
    setSubmitting(false);
  };

  const renderUploadFile = () => {
    const handleUpload = async () => {
      // const formData = new FormData();
      // fileList.forEach((file) => {
      //   // formData.append('files[]', file as RcFile);
      //   formData.append('files[]', file as any);
      // });
      setUploading(true);

      try {
        const results = await AdminUserController.uploadProfilePicture(AdminUploadProfilePictureRequest.create({
          assignedUserId: Number(userIdParam),
          file: fileList[0],
          fileUrl: fileUrl
        }));
        message.success("upload successfully. Please refresh page to see changes");
      } catch (error) {
        message.error("upload failed." + error);
      }
      setUploading(false);
    };

    const props: UploadProps = {
      disabled: fileUrl.length > 0,
      onRemove: (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        setFileList([...fileList, file]);

        return false;
      },
      fileList,
    };

    return (
      <>
        <Upload {...props}>
          <Button icon={<UploadOutlined />} disabled={fileUrl.length > 0}>Select File</Button>
        </Upload>
        <div>or</div>
        <Input placeholder='Url' disabled={fileList.length > 0} onChange={e => setFileUrl(e.target.value)} />
        <Button
          type="primary"
          onClick={handleUpload}
          disabled={fileList.length < 1 && fileUrl.length < 1}
          loading={uploading}
          style={{ marginTop: 16 }}
        >
          {uploading ? "Uploading" : "Upload Profile Picture"}
        </Button>
      </>
    );
  };

  return (
    <div className='admin-users-page'>
      <h1>Edit User Page</h1>
      <Row>
        <Col>
          <Button onClick={() => navigate(RouteConfig.ADMIN_USERS_EDIT(Number(userIdParam) - 1))}>Back</Button>
        </Col>
        <Col>
          <Button onClick={() => navigate(RouteConfig.ADMIN_USERS_EDIT(Number(userIdParam) + 1))}>Forward</Button>
        </Col>
        <Col>
          <Typography.Text italic>Note: There are basically no guardrails here</Typography.Text>
        </Col>
      </Row>
      <Skeleton active loading={loading}>
        <Row>
          <Col flex={"300px"}>
            <Image
              width={200}
              src={formData?.profilePictureUrl}
              fallback={imageNotFound}
            />
          </Col>
          <Col>
            {/* Profile Upload */}
            <h2>Update Profile Picture</h2>
            {renderUploadFile()}
          </Col>
        </Row>
        <Form
          size='middle'
          layout='horizontal'
          labelCol={{ flex: "150px" }}
          // We might also consider just a static form wrapper width of like 400 instead of "responsive"
          wrapperCol={{ sm: 18, md: 14, lg: 12, xl: 10, xxl: 8 }}
          form={form}
          initialValues={formData}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          {/* First Name */}
          <Form.Item
            label='First Name'
            name={NameOf<AdminUsersAddEditFormData>("firstName")}
            rules={[
              { required: true, message: "First Name is required" }
            ]}>
            <Input allowClear />
          </Form.Item>

          {/* Middle Name */}
          <Form.Item
            label='Middle Name'
            name={NameOf<AdminUsersAddEditFormData>("middleName")}>
            <Input allowClear />
          </Form.Item>

          {/* Last Name */}
          <Form.Item
            label='Last Name'
            name={NameOf<AdminUsersAddEditFormData>("lastName")}
            rules={[
              { required: true, message: "Last Name is required" }
            ]}>
            <Input allowClear />
          </Form.Item>

          {/* Gender */}
          <Form.Item
            label='Gender'
            name={NameOf<AdminUsersAddEditFormData>("gender")}
            rules={[
              { required: true, message: "Gender is required" }
            ]}>
            <Select options={[{ value: "Male" }, { value: "Female" }, { value: "Prefer Not to Answer" }]} />
          </Form.Item>

          {/* Email address */}
          <Form.Item
            label='Email Address'
            name={NameOf<AdminUsersAddEditFormData>("emailAddress")}
            rules={[
              { required: true, message: "Email Address is required" }
            ]}>
            <Input />
          </Form.Item>

          {/* Phone Number */}
          <Form.Item
            label='Phone Number'
            name={NameOf<AdminUsersAddEditFormData>("phoneNumber")}
          >
            <Input />
          </Form.Item>

          {/* Date of Birth */}
          <Form.Item
            label='Date of Birth'
            name={NameOf<AdminUsersAddEditFormData>("dateOfBirth")}
            shouldUpdate
            validateStatus={ageDiff < 18 ? "warning" : "success"}
            help={!Number.isNaN(ageDiff) && `Age: ${ageDiff}`}
          // rules={[
          //   { required: true, message: 'Date of Birth is required' }
          // ]}
          >
            <DatePicker />
          </Form.Item>

          {/* Role */}
          <Form.Item
            label='Role'
            name={NameOf<AdminUsersAddEditFormData>("roleId")}
            rules={[
              { required: true, message: "Role is required" }
            ]}>
            <Select options={allRoles} />
          </Form.Item>

          {/* Password */}
          <Form.Item
            label='Password'
            name={NameOf<AdminUsersAddEditFormData>("password")}
            help="SETTING THIS WILL CHANGE THE USER'S PASSWORD"
          >
            <Input.Password allowClear {...DisableAutoCompleteTags()} />
          </Form.Item>

          {/* Is Approved */}
          <Form.Item
            label='Approved'
            valuePropName="checked"
            name={NameOf<AdminUsersAddEditFormData>("isApproved")}
          >
            <Checkbox />
          </Form.Item>

          {/* Is Active */}
          <Form.Item
            label='Active'
            valuePropName="checked"
            name={NameOf<AdminUsersAddEditFormData>("isActive")}
          >
            <Checkbox />
          </Form.Item>

          {/* Language Proficiencies - OLD */}
          {/* <Form.Item label="Language Proficiencies">
            <Input.Group>
              <Form.Item
                name={[NameOf<AdminUsersAddEditFormData>('languageProficiencies'), 'languageCode']}
                noStyle
                rules={[{ required: true, message: 'Language is required' }]}
              >
                <Select options={[{ label: 'English', value: 'en-us' }]} placeholder="Select Language" />
              </Form.Item>
              <Form.Item
                name={[NameOf<AdminUsersAddEditFormData>('languageProficiencies'), 'proficiencyId']}
                noStyle
                rules={[{ required: true, message: 'Proficiency is required' }]}
              >
                <Rate allowClear={false} />
              </Form.Item>
              <span className="ant-rate-text">{proficiencyValue ? ['Bad', 'Decent', 'Good', 'Excellent', 'Proficient'][proficiencyValue - 1] : 'None'}</span>
            </Input.Group>
          </Form.Item> */}

          {/* Interpreting Services */}
          <Form.Item
            label='Interpreting Services'
            name={NameOf<AdminUsersAddEditFormData>("interpretingServices")}
          >
            <Select
              mode='multiple'
              options={ConstantsUtil.interpretingServices}

              tokenSeparators={[","]}
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase()) || (option?.extra ?? "").toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>

          {/* Language Proficiencies */}
          <Form.List
            name={NameOf<AdminUsersAddEditFormData>("languageProficiencies")}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? "Language Proficiencies" : ""}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          validator(_, value) {
                            if (value == null) {
                              return Promise.reject(new Error("Proficiency empty. Please fill or remove"));
                            }
                            if (value.languageCode == null) {
                              return Promise.reject(new Error("Please select a Language"));
                            }
                            if (value.proficiencyId == null) {
                              return Promise.reject(new Error("Please select a Proficiency Level"));
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                      noStyle
                    >
                      <LanguageProficiencySelector />
                    </Form.Item>
                    <MinusCircleOutlined
                      style={{
                        position: "relative",
                        top: 4,
                        marginLeft: 8,
                        fontSize: 24,
                        cursor: "pointer",
                        transition: "all 0.3"
                      }}
                      onClick={() => remove(field.name)}
                    />
                  </Form.Item>
                ))}

                {/* {fields.map(({ key, name, ...restField }) => (
                  <Form.Item
                    {...restField}
                    key={key}
                    name={[name, 'languageCode']}
                    rules={[{ required: true, message: 'Missing first name' }]}
                  >
                    <LanguageProficiencySelector />
                  </Form.Item>
                  // <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                  //   <Form.Item
                  //     {...restField}
                  //     name={[name, 'languageCode']}
                  //     rules={[{ required: true, message: 'Missing first name' }]}
                  //   >
                  //     <Input placeholder="First Name" />
                  //   </Form.Item>
                  //   <Form.Item
                  //     {...restField}
                  //     name={[name, 'proficiencyId']}
                  //     rules={[{ required: true, message: 'Missing last name' }]}
                  //   >
                  //     <Input placeholder="Last Name" />
                  //   </Form.Item>
                  //   <MinusCircleOutlined onClick={() => remove(name)} />
                  // </Space>
                ))} */}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add Proficiency
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* Addresses */}
          <Collapse>
            <Collapse.Panel header="Mailing Address" key="1">
              <p>This is the users mailing address and will be used for things like stripe, communication and verification</p>

              {/* Address Line 1 */}
              <Form.Item
                label='Address Line 1'
                name={[NameOf<AdminUsersAddEditFormData>("mailingAddress"), NameOf<AddressDTO>("addressLine1")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* Address Line 2 */}
              <Form.Item
                label='Address Line 2'
                name={[NameOf<AdminUsersAddEditFormData>("mailingAddress"), NameOf<AddressDTO>("addressLine2")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* Address Line 3 */}
              <Form.Item
                label='Address Line 3'
                name={[NameOf<AdminUsersAddEditFormData>("mailingAddress"), NameOf<AddressDTO>("addressLine3")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* City */}
              <Form.Item
                label='City'
                name={[NameOf<AdminUsersAddEditFormData>("mailingAddress"), NameOf<AddressDTO>("city")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* Region */}
              <Form.Item
                label='State or Region'
                name={[NameOf<AdminUsersAddEditFormData>("mailingAddress"), NameOf<AddressDTO>("region")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* Postal Code */}
              <Form.Item
                label='Zip or Postal Code'
                name={[NameOf<AdminUsersAddEditFormData>("mailingAddress"), NameOf<AddressDTO>("postalCode")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* Country */}
              <Form.Item
                label='Country'
                name={[NameOf<AdminUsersAddEditFormData>("mailingAddress"), NameOf<AddressDTO>("country")]}
              >
                <Input allowClear />
              </Form.Item>

            </Collapse.Panel>
            <Collapse.Panel header="Current Address" key="2">
              <p>The current address is roughly where the user is right now. This is used when traveling</p>

              {/* Address Line 1 */}
              <Form.Item
                label='Address Line 1'
                name={[NameOf<AdminUsersAddEditFormData>("currentAddress"), NameOf<AddressDTO>("addressLine1")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* Address Line 2 */}
              <Form.Item
                label='Address Line 2'
                name={[NameOf<AdminUsersAddEditFormData>("currentAddress"), NameOf<AddressDTO>("addressLine2")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* Address Line 3 */}
              <Form.Item
                label='Address Line 3'
                name={[NameOf<AdminUsersAddEditFormData>("currentAddress"), NameOf<AddressDTO>("addressLine3")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* City */}
              <Form.Item
                label='City'
                name={[NameOf<AdminUsersAddEditFormData>("currentAddress"), NameOf<AddressDTO>("city")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* Region */}
              <Form.Item
                label='State or Region'
                name={[NameOf<AdminUsersAddEditFormData>("currentAddress"), NameOf<AddressDTO>("region")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* Postal Code */}
              <Form.Item
                label='Zip or Postal Code'
                name={[NameOf<AdminUsersAddEditFormData>("currentAddress"), NameOf<AddressDTO>("postalCode")]}
              >
                <Input allowClear />
              </Form.Item>

              {/* Country */}
              <Form.Item
                label='Country'
                name={[NameOf<AdminUsersAddEditFormData>("currentAddress"), NameOf<AddressDTO>("country")]}
              >
                <Input allowClear />
              </Form.Item>
            </Collapse.Panel>
          </Collapse>

          {/* <Form.Item shouldUpdate>
            {() => {
              return <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>;
            }}
          </Form.Item> */}

          {/* Submit */}
          <Space style={{ marginTop: 12 }}>
            <Button type='primary' htmlType="submit" style={{ padding: "0 20px" }} loading={loading || submitting}>Save Changes</Button>
            <Button danger type='primary' style={{ padding: "0 20px" }} loading={loading || submitting} onClick={handleDelete}>Delete</Button>
            <br />
            <Button type='primary' style={{ padding: "0 20px" }} loading={loading || submitting} onClick={handleExport}>Export</Button>
          </Space>
        </Form>
      </Skeleton>
    </div>
  );
};

export default AdminUsersEditPage;
