import * as React from "react";
import { Avatar, Button, Col, Divider, Dropdown, Layout, Menu, Row, Space, Typography } from "antd";
import { BarChartOutlined, BookOutlined, FacebookFilled, FacebookOutlined, HomeOutlined, InstagramFilled, LinkedinFilled, LinkedinOutlined, MenuOutlined, PoweroffOutlined, SettingOutlined, TwitterOutlined, TwitterSquareFilled, UserOutlined, UserSwitchOutlined, YoutubeFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import RouteConfig from "../config/RouteConfig";
import { AuthenticationContext } from "src/providers/AuthenticationContext";
import "./StandardFooter.less";

const { Footer } = Layout;

const StandardFooter: React.FC = (props) => {
  // align-items: center;
  return (
    <Footer className='standard-footer'>
      <Row className='footer-row'>
        {/* Left Side */}
        <Col className='left-side' span={12}>
          <Row gutter={[0, 8]}>
            <Col className='icons' span={24}>
              <Space size={20}>
                <YoutubeFilled />
                <InstagramFilled />
                <TwitterOutlined />
                <LinkedinFilled />
                <FacebookFilled />
              </Space>
            </Col>
            <Col className='words' span={24}>
              <Space split={<Divider type="vertical" />}>
                {/* <Typography.Link>Link</Typography.Link>
                <Typography.Link>Link</Typography.Link>
                <Typography.Link>Link</Typography.Link> */}
                <span>FAQs</span>
                <span>Contact Us</span>
                <span>Terms &amp; Conditions</span>
              </Space>
            </Col>
          </Row>
        </Col>

        {/* Right Side */}
        <Col className='right-side' span={12}>
          &nbsp;
        </Col>
      </Row>
    </Footer>
  );
};

export default StandardFooter;
