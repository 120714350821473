import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Image, Row, Skeleton } from "antd";
import { StringUtil } from "src/utils";
import { useParams } from "react-router";
import { AuthenticationContext } from "src/providers/AuthenticationContext";
import NotificationUtil from "src/utils/NotificationUtil";
import InterpreterDisplayResponse from "src/models/generated/InterpreterDisplayResponse";
import UserController from "src/api/UserController";
import joinTeamImage from "src/assets/people-giving-thumbs-up.jpg";
import imageNotFound from "src/assets/core/image-not-found.png";
import TimeIntervalDTO from "src/models/generated/TimeIntervalDTO";
import moment from "moment-timezone";

const InterpreterProfilePage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const { userPublicId: userPublicIdParam } = useParams();
  // Might go the create path for now and see where that gets us
  const [pageData, setPageData] = useState<InterpreterDisplayResponse>(InterpreterDisplayResponse.create());
  const [availability, setAvailability] = useState<TimeIntervalDTO[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadPageData();
  }, []);

  const loadPageData = async () => {
    if (StringUtil.IsNullOrEmpty(userPublicIdParam)) {
      // Do something with the error
      NotificationUtil.error({
        key: "InterpreterProfilePage",
        message: "Interpreter",
        description: "Error while loading interpreter!",
      });
      return;
    }

    setLoading(true);
    const profileResult = await UserController.getInterpreterById(userPublicIdParam);
    const availabilityResult = await UserController.getInterpreterAvailability(userPublicIdParam, moment.tz.guess());

    setPageData(profileResult.data);
    setAvailability(availabilityResult.data);
    setLoading(false);
  };

  return (
    <div className='interpreter-profile-page' style={{ textAlign: "left" }}>
      <h1>Agent: {pageData?.displayName}</h1>

      <Skeleton active loading={loading}>
        <div className='bio-and-profile-picture'>
          <Row className='with-background-light-grey'>
            <Col span={16} className='with-padding'>
              <h2 className='with-text-uppercase'>Bio</h2>
              <p className='with-special-paragraph'>Fill your information and join our team. This site is dedicated to helping travelers and businesses find professional, affordable, and reliable interpreters, as well as personal guides in the country of Ukraine. Professionalism and integrity are the cornerstones of our business.</p>
            </Col>
            <Col span={8}>
              <Image width={"100%"} className='profile-image' src={pageData.profilePictureUrl!} fallback={imageNotFound} />
            </Col>
          </Row>
          <h1 className='with-margin-top with-text-uppercase'>Description</h1>
          <Card>
            <Row>
              <Col span={12}>
                <Row gutter={[0, 24]}>
                  <Col span={24}>
                    <h2 className='with-text-uppercase'>Language Skills</h2>
                    {pageData?.languageProficiencies?.map((x, i) => <p key={i}>{x.languageName}: {x.proficiencyName}</p>)}
                  </Col>
                  <Col span={24}>
                    <h2 className='with-text-uppercase'>Services</h2>
                    <p>Flower Delivery, Interpreting, Tour Guide</p>
                    {pageData?.interpretingServices?.map((x, i) => <p key={i}>{x.serviceName}</p>)}
                  </Col>
                  {/* <Col span={24}>
                    <h2 className='with-text-uppercase'>Tours</h2>
                    <ul>
                      <li>N/A</li>
                    </ul>
                  </Col> */}
                  <Col span={24}>
                    <h2 className='with-text-uppercase'>Rates</h2>
                    <ul>
                      <li>Flower Delivery - Daytime: $40/hr</li>
                      <li>Interpreting - Daytime: $60/hr</li>
                      <li>Interpreting - Nighttime: $100/hr</li>
                      <li>Tour Guide - Daytime: $50/hr</li>
                      {/* {pageData?.rates?.map((x, i) => <li key={i}>{x.rateName}</li>)} */}
                    </ul>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <h3>Availability</h3>
                <div>
                  I think that the timezone is: {moment.tz.guess()}
                </div>
                <ul>
                  {availability.map((x, i) => <li key={i}>{`${x.start!.format("ddd, h:mm A")} - ${x.end!.format("h:mm A")}`}</li>)}
                </ul>

              </Col>
            </Row>

          </Card>
        </div>
      </Skeleton>
    </div>
  );
};

export default InterpreterProfilePage;
