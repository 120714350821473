
/** Handles the internal workings for the routes file */
export class RouteUrl {
    /**
     * Returns the original URL provided before adding in the params
     */
    public readonly originalUrl: string;
    /**
     * Returns the original Params as an object
     */
    public readonly originalParams?: Record<string, unknown>;

    /**
     * Returns the route generated from the URL and Params
     */
    public toString(): string {
        // Dev Note: We might be able to use react-router's 'generatePath()' instead
        // import { generatePath } from 'react-router'
        return this.generateRoute();
    }

    /**
     * Creates an instance of RouteUrl
     * @example
     * // Simple
     * RouteUrl('/path/:id', { id });
     *
     * // Complicated. Notice the matching keys and url params
     * RouteUrl('/path/:foo/:bar/:baz?', { foo, bar: id, baz: 'something' });
     *
     * @remarks
     * If params are in the URL and **not** passed into the params param, then the route is not parsed and will equal the url passed in
     *
     * @param {string} url The url as a React Router url string, see examples
     * @param {{}} [params] The params for the url as an object. Items must be keyed corresponding to their name in the url
     */
    constructor(url: string, params?: Record<string, unknown>) {
        // Remove spaces from url
        this.originalUrl = url.replace(/(\r\n|\n|\r| )/gm, "");
        this.originalParams = params; // as { [index: string]: string };
    }

    private generateRoute(): string {
        // If we have no params, skip parsing.
        if (this.originalParams == null || Object.keys(this.originalParams).length < 1) {
            return this.originalUrl;
        }

        const paramKeys = Object.keys(this.originalParams);

        // Purely for readability on the return statement below
        const mapFunction = (split: string): string => {
            let isOptional = false;

            // Check if variable or just string
            if (split[0] !== ":") {
                return split;
            }

            // Remove the ':' from the start of the key
            split = split.slice(1);

            // Check if required
            if (split.slice(-1) === "?") {
                isOptional = true;
                split = split.slice(0, -1);
            }

            // Check if params match. If not, optional params just get let through
            let key = paramKeys.find(y => y === split);
            if (key == null && isOptional) {
                return "";
            } else if (key == null) {
                key = ""; // Dev Note: Probably should throw an error here
            }

            return String(this.originalParams![key]);
        };

        return "/" + this.originalUrl
            .split("/") // Split into chunks
            .map(mapFunction) // Map params to variables
            .filter(x => x != null && x.trim().length > 0) // Remove empty
            .join("/"); // Join everything back together again
    }
}


export class NewRouteUrl {
    /**
     * Returns the path or URL originally provided
     */
    public readonly path: string;
    /**
     * Returns the required Params as an object
     */
    public readonly requiredParams?: Record<string, React.Key>;

    /**
     * Returns the required Params as an object
     */
    public readonly optionalParams?: Record<string, React.Key>;

    /**
     * Returns the route generated from the URL and Params
     */
    public toString(): string {
        // Dev Note: We might be able to use react-router's 'generatePath()' instead
        // import { generatePath } from 'react-router'
        return this.generateRoute();
    }

    /**
     * Creates an instance of RouteUrl
     * @example
     * // Simple
     * RouteUrl('/path/:id', { id });
     *
     * // Complicated. Notice the matching keys and url params
     * RouteUrl('/path/:foo/:bar/:baz?', { foo, bar: id, baz: 'something' });
     *
     * // Optional parameters
     * RouteUrl('/path', {}, { id: 12 });
     * // yields -> /path?id=12
     *
     * @remarks
     * Missing required params in the `path` will be skipped, throwing an error to the console.
     *
     * Optional params in the `path` will be removed
     *
     * @param {string} url The url as a React Router url string, see examples
     * @param {Record<string, React.Key>} [params] The params for the url as an object. Items must be keyed corresponding to their name in the url
     */
    constructor(url: string, requiredParams?: Record<string, React.Key>, optionalParams?: Record<string, React.Key>) {
        // Remove spaces from url
        this.path = url.replace(/(\r\n|\n|\r| )/gm, "");
        this.requiredParams = requiredParams;
        this.optionalParams = optionalParams;
    }

    private generateRoute(): string {
        // If we have no params, skip parsing.
        // if (this.requiredParams == null || Object.keys(this.requiredParams).length < 1) {
        //     return this.path;
        // }

        // I am thinking of a new philosophy on this
        // a path of "/path/:id/page/:something??text=something%20fun" is kind of nasty imo
        // What if all replacements were wrapped and we can deal with it that way? Hmmm
        // /path/[id]/page/[?something]?[text]&[?potato]

        // Match all []'s   /\[[^\]]+\]/g

        let fun = this.path.match(/\[[^\]]+\]/g);
        if (fun == null) {
            // Then there are no parameters to parse out
        }






        // const paramKeys = Object.keys(this.originalParams);

        // // Purely for readability on the return statement below
        // const mapFunction = (split: string): string => {
        //     let isOptional = false;

        //     // Check if variable or just string
        //     if (split[0] !== ':') {
        //         return split;
        //     }

        //     // Remove the ':' from the start of the key
        //     split = split.slice(1);

        //     // Check if required
        //     if (split.slice(-1) === '?') {
        //         isOptional = true;
        //         split = split.slice(0, -1);
        //     }

        //     // Check if params match. If not, optional params just get let through
        //     let key = paramKeys.find(y => y === split);
        //     if (key == null && isOptional) {
        //         return '';
        //     } else if (key == null) {
        //         key = ''; // Dev Note: Probably should throw an error here
        //     }

        //     return String(this.originalParams![key]);
        // };

        // return '/' + this.originalUrl
        //     .split('/') // Split into chunks
        //     .map(mapFunction) // Map params to variables
        //     .filter(x => x != null && x.trim().length > 0) // Remove empty
        //     .join('/'); // Join everything back together again
        return "";
    }
}