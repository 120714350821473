import * as React from "react";
import { BaseOptionType } from "antd/lib/select";
import Roles from "src/core/Roles";
import StandardConstant from "src/models/frontend/common/StandardConstant";


/**
 * Utility to handle the constants for now. These will eventually be driven by a context, like the authcontext
 */
class ConstantsUtil {
  roles = Roles;

  /** DEPRECATED: TODO: Please remove */
  roles_old: BaseOptionType[] = [
    { value: 1, label: "Administrator" },
    { value: 2, label: "Moderator" },
    { value: 3, label: "Interpreter" },
    { value: 4, label: "Customer" },
  ];

  languages: StandardConstant[] = [
    { key: "en", label: "English" },
    { key: "uk", label: "Ukrainian" },
    { key: "ru", label: "Russian" },
    { key: "fr", label: "French" },
    { key: "de", label: "German" },
    { key: "es", label: "Spanish" },
    { key: "it", label: "Italian" },
    { key: "ja", label: "Japanese" },
    { key: "ko", label: "Korean" },
  ];

  proficiencies: StandardConstant[] = [
    { key: 6, order: 5, label: "Native",       extra: "native"       },
    { key: 5, order: 4, label: "Fluent",       extra: "fluent"       },
    { key: 4, order: 3, label: "Advanced",     extra: "advanced"     },
    { key: 3, order: 2, label: "Intermediate", extra: "intermediate" },
    { key: 2, order: 1, label: "Basic",        extra: "basic"        },
    { key: 1, order: 0, label: "None",         extra: "none"         },
  ];

  genders: StandardConstant[] = [
    { key: "male",        order: 0, label: "Male",                  extra: "male"      },
    { key: "female",      order: 1, label: "Female",                extra: "female"    },
    { key: "no_answer",   order: 2, label: "Prefer Not to Answer",  extra: "no_answer" },
  ];

  interpretingServices: StandardConstant[] = [
    { key: 1,  label: "Interpreting",                    extra: "interpreting"                    },
    { key: 2,  label: "Translation",                     extra: "translation"                     },
    { key: 3,  label: "Personal Guiding",                extra: "personal_guiding"                },
    { key: 4,  label: "Online Interpreting",             extra: "online_interpreting"             },
    { key: 5,  label: "Phone Conversations",             extra: "phone_conversations"             },
    { key: 6,  label: "Tours/Excursions",                extra: "tours_excursions"                },
    { key: 7,  label: "Documents, Specs",                extra: "documents_specs"                 },
    { key: 8,  label: "Correspondence Forward",          extra: "correspondence_forward"          },
    { key: 9,  label: "Arranging Accommodation",         extra: "arranging_accommodation"         },
    { key: 10, label: "Airport Transfer",                extra: "airport_transfer"                },
    { key: 11, label: "Train/Plane Tickets Reservation", extra: "train_plane_tickets_reservation" },
    { key: 12, label: "Driving/Taxi",                    extra: "driving_taxi"                    },
    { key: 13, label: "Cell Phone Rental",               extra: "cell_phone_rental"               },
    { key: 14, label: "Flowers & Gifts Delivery",        extra: "flowers_gifts_delivery"          },
    { key: 15, label: "Discovery/Investigation",         extra: "discovery_investigation"         },
    { key: 16, label: "Advice",                          extra: "advice"                          },
    { key: 17, label: "Personal Driver",                 extra: "personal_driver"                 },
    { key: 18, label: "Apartment Rentals",               extra: "apartment_rentals"               },
  ];
}

export default new ConstantsUtil();
