import React, { useContext, useState } from "react";
import { Col, Layout, Menu, MenuProps, Row } from "antd";
import {
  DesktopOutlined,
  FileOutlined,
  HomeOutlined,
  PieChartOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { AuthenticationContext } from "src/providers/AuthenticationContext";
import "./UserLayout.less";
import { NavLink } from "react-router-dom";
import RouteConfig from "src/config/RouteConfig";

const { Header, Content, Footer, Sider } = Layout;

const UserLayout: React.FC<React.PropsWithChildren> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [collapsed, setCollapsed] = useState(false);

  let menuItems: Required<MenuProps["items"]> = [
    { key: "1", label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Home</NavLink>, icon: <HomeOutlined /> }
  ];

  // Dev Note: I think that, to get the default selectedKeys working, I will need to move the menuItems to a different object, one where I have access to the URL, and then build it as react components

  // TODO: Make this a constant somewhere
  switch (authContext.auth?.roleId) {
    case 1:
      // Admin
      menuItems.push({ key: "2", label: <NavLink to={{ pathname: RouteConfig.LINK.ADMIN_LIST_USERS }}>Users</NavLink>, icon: <UsergroupAddOutlined /> });
      menuItems.push({ key: "3", label: <NavLink to={{ pathname: RouteConfig.LINK.PROFILE }}>Profile</NavLink>, icon: <UserOutlined /> });
      break;
    case 2:
      // Moderator
      menuItems.push({ key: "2", label: <NavLink to={{ pathname: RouteConfig.LINK.PROFILE }}>Profile</NavLink>, icon: <UserOutlined /> });
      break;
    case 3:
      // Interpreter
      menuItems.push({ key: "2", label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Appointments</NavLink>, icon: <UserOutlined /> });
      menuItems.push({ key: "3", label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Schedule</NavLink>, icon: <UserOutlined /> });
      menuItems.push({ key: "4", label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Profile</NavLink>, icon: <UserOutlined /> });
      break;
    case 4:
      // Customer
      menuItems.push({ key: "2", label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Profile</NavLink>, icon: <UserOutlined /> });
      // This will be the base page for the appointments
      menuItems.push({ key: "2", label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Appointment</NavLink>, icon: <UserOutlined />, children: [
        { key: "21", label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>New Appointment</NavLink>, icon: <UserOutlined /> },
        { key: "22", label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Edit Appointment</NavLink>, icon: <UserOutlined /> }
      ] });
      menuItems.push({ key: "2", label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Search Interpreters</NavLink>, icon: <UserOutlined /> });
      break;
    default:
      menuItems.push({ key: "2", label: <NavLink to={{ pathname: RouteConfig.LINK.HOME }}>Profile</NavLink>, icon: <UserOutlined /> });
      break;
  }
  // [
  //   { key: '1', label: 'Mike?', icon: <PieChartOutlined /> },
  //   { key: '2', label: 'No', icon: <DesktopOutlined /> },
  //   { key: '3', label: 'It\'s Robin', icon: <UserOutlined /> },
  //   { key: '4', label: 'Mlem', icon: <TeamOutlined /> }
  // ]

  return (
    <Layout className='user-layout'>
      <Sider className='layout-sider' collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
        <div className="logo" />
        <Menu className='layout-sider-menu' theme="dark" defaultSelectedKeys={["1"]} mode="inline" items={menuItems} />
      </Sider>
      <Content>
        <Layout className="content-layout">
          <Header className="layout-header">

            <Row justify='space-between'>
              <Col>Account</Col>
              <Col>
                <Row style={{ fontSize: 20, fontWeight: "bold", width: 150 }}>
                  <Col style={{ marginRight: 8 }}>Layout:</Col>
                  <Col xs={12} sm={0}>XS</Col>
                  <Col xs={0} sm={12} md={0}>SM</Col>
                  <Col xs={0} md={12} lg={0}>MD</Col>
                  <Col xs={0} lg={12} xl={0}>LG</Col>
                  <Col xs={0} xl={12} xxl={0}>XL</Col>
                  <Col xs={0} xxl={12}>XXL</Col>
                </Row>
              </Col>
            </Row>
          </Header>
          <Content className='content-container'>
            {props.children}
            <Footer style={{ textAlign: "center" }}>Interpreters World ©2023</Footer>
          </Content>
        </Layout>
      </Content>
    </Layout>
  );
};

export default UserLayout;
